import styled from 'styled-components';
import Popup from 'reactjs-popup';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { avatarDropDownValues } from '../../../const/common';
import { Icon } from '../icons/Icon';

const AvatarWrapper = styled.div`
  border-radius: 15px;
  background: ${(props) => props.theme.colors.purple40};
  padding: 10px;
  color: ${(props) => props.theme.colors.white};
  font-family: Avenir;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  height: fit-content;
  line-height: 22px;
  cursor: pointer;
`;

const StyledPopup = styled(Popup)`
  &-overlay {
    margin-bottom: 20px;
  }
`;

const Menu = styled.div`
  margin-top: 10px;
  background-color: ${(props) => props.theme.colors.white};
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.colors.black20};
  line-height: 22px;
  letter-spacing: -0.32px;
  box-shadow: 0px 4px 20px rgba(34, 41, 57, 0.15);
  border-radius: 8px;
  min-width: 200px;
`;

const MenuItem = styled.div`
  display: flex;
  padding: 10px 20px;
  cursor: pointer;
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  align-items: flex-end;
  justify-content: flex-start;
  img {
    margin-right: 10px;
  }
  :hover {
    background: ${(props) => props.theme.colors.purple01};
  }
`;

const StyledImg = styled.img``;

export const Avatar = () => {
  const { t } = useTranslation(['common']);
  const router = useRouter();
  return (
    <StyledPopup
      trigger={
        <AvatarWrapper>
          <Icon src="/assets/icons/avatar-icon.svg" alt="avatar menu" ariaLabel="avatar-icon" />
        </AvatarWrapper>
      }
      position="bottom right"
      on={['click']}
      arrow={false}
      padBottom="10px">
      <Menu>
        {avatarDropDownValues.map((value) => {
          const { path, name, icon, alt } = value;

          if (router.pathname === '/settings' && path === '/settings') {
            return null;
          }

          return (
            <Link href={path} key={value.name} passHref>
              <MenuItem>
                <StyledImg src={icon} alt={alt} aria-label={name} />
                {t(name)}
              </MenuItem>
            </Link>
          );
        })}
      </Menu>
    </StyledPopup>
  );
};

import styled from 'styled-components';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { media } from '../utils/media';
import { BackLinkIcon } from './common/icons/BackLinkIcon';
import { Avatar } from './common/header/Avatar';
import { addProductButton, renderMatrixMenu } from './common/header/HeaderAssets';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 20px 0 40px;
  align-items: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

const Title = styled.h1`
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  color: ${(props) => props.theme.colors.black15};
  margin: unset;
`;

const ActionButtonsWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  ${media.TABLET`margin-top: 10px;`}
`;

const buildPageAssets = (router) => {
  const { pathname } = router;

  const { t } = useTranslation(['common', 'products', 'overview']);
  switch (pathname) {
    case '/products':
      return {
        title: t('navigation.my_products'),
        assets: () => addProductButton(true)
      };
    case '/orders':
      return {
        title: t('navigation.my_orders'),
        assets: () => renderMatrixMenu()
      };
    case '/chat':
      return {
        title: 'My Messages',
        assets: () => renderMatrixMenu()
      };
    case '/orders/[id]':
      return {
        title: t('navigation.view_order'),
        backLinkFn: () => router.back(),
        assets: () => renderMatrixMenu()
      };
    case '/orders/import_tracking':
      return {
        title: t('navigation.orders_import_tracking_numbers'),
        backLinkFn: () => router.back(),
        assets: () => renderMatrixMenu()
      };
    case '/listings/[id]':
      return {
        title: t('navigation.edit_product')
      };
    case '/listings':
      return {
        title: t('products:add_products_button')
      };
    case '/settings/password':
      return {
        title: t('navigation.settings')
      };
    case '/settings/account':
      return {
        title: t('navigation.settings')
      };
    case '/settings/shopify-integration':
      return {
        title: t('navigation.settings')
      };
    case '/settings/gettingpaid':
      return {
        title: t('navigation.settings')
      };
    case '/settings/shipping':
      return {
        title: t('navigation.settings')
      };
    case '/settings/policy':
      return {
        title: t('navigation.settings')
      };

    default:
      return { title: t('overview:title') };
  }
};

export const HeaderPanel = () => {
  const router = useRouter();

  const { title = '', assets = () => {}, backLinkFn = null } = buildPageAssets(router);

  return (
    <Wrapper data-cy="header-panel">
      <TitleWrapper>
        {backLinkFn && <BackLinkIcon clickHandler={() => backLinkFn()} />}
        <Title>{title}</Title>
      </TitleWrapper>
      <ActionButtonsWrapper>
        {assets()}
        <Avatar />
      </ActionButtonsWrapper>
    </Wrapper>
  );
};

import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';

import { BoldXIcon } from './icons/BoldXIcon';

export const FileIcon = styled.img`
  width: 50px;
  height: 68px;
  ${(props) =>
    props.small &&
    `
    width: 38px;
    height: 52px;
  `}

  ${(props) =>
    props.extraSmall &&
    `
    width: 38px;
    height: 52px;
  `}
`;

FileIcon.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string
};

FileIcon.defaultProps = {
  src: '/assets/icons/file-icon.svg',
  alt: 'file icon'
};

const XIcon = styled(BoldXIcon)`
  display: flex;
  background-color: ${(props) => props.theme.colors.red10};
  margin-right: 17px;
  margin-top: 4px;
  width: 20px;
  height: 19px;
  padding: 6px 5px 0 6px;
  border-radius: 5px;
`;

const ErrorWrapper = styled.div`
  display: flex;
  align-content: flex-start;
`;

const Message = styled.div`
  color: ${(props) => props.theme.colors.red03};
  font-family: Avenir;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.0025em;
`;

export const ErrorMessage = ({ message }) => {
  return (
    <ErrorWrapper>
      <XIcon />
      <Message>{message}</Message>
    </ErrorWrapper>
  );
};

ErrorMessage.propTypes = {
  message: PropTypes.string
};

ErrorMessage.defaultProps = {
  message: ''
};

const ErrorContainer = styled.div`
  background-color: ${(props) => props.theme.colors.red00};
  margin: 36px 0 24px;
  border-radius: 12px;
  padding: 17px 12px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

const Col = styled.div``;

const Icon = styled.img`
  margin-right: -12px;
`;

const Header = styled.li`
  font-family: Avenir;
  font-size: 12px;
  font-weight: 900;
  color: ${(props) => props.theme.colors.red06};
  list-style: none;
  margin-left: -16px;
`;

const UnList = styled.ul`
  font-family: Avenir;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${(props) => props.theme.colors.red06};
`;

export const ErrorCSV = ({ error }) => {
  const { t } = useTranslation(['common']);

  if (error.Errors) {
    return (
      <ErrorContainer>
        <Row>
          <Col>
            <Icon src="/assets/icons/info.svg" />
          </Col>
          <Col>
            <UnList>
              <Header>{t('error_csv_modal')}</Header>
            </UnList>
          </Col>
        </Row>
      </ErrorContainer>
    );
  }

  const { 'Missing headers': missing_headers, 'Invalid values': invalid_values } = error;

  return (
    <ErrorContainer>
      <Row>
        <Col>
          <Icon src="/assets/icons/info.svg" />
        </Col>
        <Col>
          <UnList>
            <Header hidden={!missing_headers}>{t('missing_headers')}</Header>
            {missing_headers?.map((el) => (
              <li>{el}</li>
            ))}
            <Header hidden={!invalid_values}>{t('invalid_values')}</Header>
            {invalid_values?.map((el) => (
              <li>{el}</li>
            ))}
          </UnList>
        </Col>
      </Row>
    </ErrorContainer>
  );
};

ErrorCSV.propTypes = {
  error: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
};

ErrorCSV.defaultProps = {
  error: 'The application has encountered an unknown error.'
};

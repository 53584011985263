import { useState } from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { MobilePlusMajor, ArrowDownMinor } from '@shopify/polaris-icons';
import { useTranslation } from 'next-i18next';

import { exportCSV } from '../../../utils/helpers';
import { createToastMessage } from '../../../utils/hooks';

import { Button } from '../Button';
import { CustomIcon } from '../CustomIcon';
import { MatrixMenu } from './MatrixMenu';
import { ImportCSVModal } from '../Modal/ImportCSVModal';

const AdditionalButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  ${(props) =>
    props.showPipe &&
    `
    margin-right: 0px;
    :after {
      content: ' ';
      width: 1px;
      height: 30px;
      margin: 0 20px;
      background-color: #a1a1a1;
    }
  `}
`;

const ButtonCopyWrapper = styled.span`
  display: flex;
  padding: 2px 4px;
  justify-content: space-between;
  align-items: flex-end;
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  img {
    width: 18px;
    margin-right: 5px;
  }
`;

const StyledButton = styled(Button)`
  padding: 8px 15px;
`;

const TextButton = styled.button`
  padding: 0;
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => props.theme.colors.black10};
  margin-right: 26px;
  background-color: transparent;
  border: 0;
  box-shadow: none;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCustomIcon = styled(CustomIcon)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const addProductButton = (showPipe) => {
  const { t } = useTranslation(['products']);

  const [modalState, setModal] = useState(false);

  const handleExport = async () => {
    await exportCSV('inventory-list');
    createToastMessage({
      type: 'success',
      message: t('messages.exported_csv_sucessfully')
    });
  };

  return (
    <AdditionalButtonWrapper showPipe={showPipe}>
      <ImportCSVModal isOpen={modalState} setModal={setModal} />
      <TextButton
        onClick={() => {
          setModal(!modalState);
        }}>
        {t('import_csv_products')}
      </TextButton>
      <TextButton onClick={handleExport}>
        <ButtonWrapper>
          <StyledCustomIcon iconType={ArrowDownMinor} color="#000" margin="4px 0 0 0" width="76%" />
          {t('export_csv_products')}
        </ButtonWrapper>
      </TextButton>
      <Link href="/listings">
        <a>
          <StyledButton onClick={() => {}}>
            <ButtonCopyWrapper>
              <CustomIcon iconType={MobilePlusMajor} testId="plus-sign" />
              {t('add_products_button')}
            </ButtonCopyWrapper>
          </StyledButton>
        </a>
      </Link>
    </AdditionalButtonWrapper>
  );
};

export const renderMatrixMenu = () => {
  return (
    <AdditionalButtonWrapper showPipe>
      <MatrixMenu />
    </AdditionalButtonWrapper>
  );
};

import styled from 'styled-components';
import Link from 'next/link';
import { useContext } from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'next-i18next';
import { ChatMajor } from '@shopify/polaris-icons';

import { navigationValues, settings } from '../const/common';
import { media, MEDIA_QUERIES } from '../utils/media';
import Context from '../providers/Context';
import { HeaderPanel } from './HeaderPanel';
import { Footer } from './Footer';
import { CustomIcon } from './common/CustomIcon';
import { useSettings } from '../services/api';

const PageWrapper = styled.div`
  display: flex;
  height: 100%;
  height: 100vh;
  max-width: 1920px;
  margin: 0 auto;
`;

const NavWrapper = styled.div`
  min-width: 275px;
  max-width: 275px;
  background: ${(props) => props.theme.colors.dark};
  height: 100vh;
  display: flex;
  flex-direction: column;
  ${media.TABLET`
    min-width: 50px;
  `}
`;

const LogoBadge = styled.img`
  width: 50%;
  margin-top: 50px;
`;

// TODO this will need adjusting with padding and other styles - for now just a placeholder

const BodyWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow-x: scroll;
  background-color: ${(props) => props.theme.colors.blue01};
`;

const InnerWrapper = styled.div`
  width: fill-available;
  padding: 20px 50px;
  ${media.TABLET`
    padding: 10px;
  `}
  max-width: 1500px;
`;

const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 10%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
  :after {
    content: ' ';
    width: 200px;
    height: 1px;
    margin: 9em auto;
    position: absolute;
    background-color: ${(props) => props.theme.colors.grey07};
    opacity: 0.06;
    ${media.TABLET`
      width: 25px;
      margin: 7em auto;
    `}
  }
`;

const StyledImage = styled.img`
  margin-top: 50px;
`;

const NavItemContainer = styled.div`
  display: flex;
  height: fit-content;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;
  min-height: 500px;
`;

const TopNavItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  height: 60%;
  .isSelected {
    background: ${(props) => props.theme.colors.purple40};
    border-radius: 6px;
    font-weight: 700;
  }
  ${media.TABLET`align-items: center;`}
`;

const NavItem = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.white};
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  padding: 10px;
  margin: 10px 0;
  cursor: pointer;

  :hover {
    background: ${(props) => props.theme.colors.purple50};
    border-radius: 6px;
  }
`;

const NavText = styled.span`
  ${media.TABLET`display:none;`}
`;

const SettingsWrapper = styled(TopNavItemContainer)`
  height: fit-content;
  margin-bottom: 50px;
`;

const ToastWrapper = styled(ToastContainer)`
  .toast {
    min-height: 47px;
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    width: fit-content;
  }
  .success-toast {
    background: ${(props) => props.theme.colors.green01};
    color: ${(props) => props.theme.colors.green10};
    border: ${(props) => `1px solid ${props.theme.colors.green02}`};
  }
  .error-toast {
    background: ${(props) => props.theme.colors.red01};
    color: ${(props) => props.theme.colors.red10};
    border: ${(props) => `1px solid ${props.theme.colors.red02}`};
  }
  .warning-toast {
    background: ${(props) => props.theme.colors.yellow01};
    color: ${(props) => props.theme.colors.yellow10};
    border: ${(props) => `1px solid ${props.theme.colors.yellow02}`};
  }
`;

export const NavigationWrapper = ({ children, showNavigation = true }) => {
  const { t } = useTranslation(['common']);
  const navValues = navigationValues(t);
  const router = useRouter();
  const { data } = useSettings();
  const chatEnabled = data && data.supplier && data.supplier.chat_enabled;

  if (chatEnabled) {
    navValues.push({
      name: 'My Messages',
      path: '/chat',
      icon: ChatMajor
    });
  }

  const {
    screenSize: { screenWidth }
  } = useContext(Context);

  const renderNavItem = (item) => {
    const { path, name, icon } = item;

    const isSelectedMenuItem =
      path === router.pathname ||
      (path === '/settings' && router.pathname.startsWith('/settings/'));

    return (
      <Link href={path} key={name} passHref>
        <NavItem className={classnames({ isSelected: isSelectedMenuItem })}>
          <CustomIcon iconType={icon} testId={name} />
          <NavText>{t(`common:${name}`)}</NavText>
        </NavItem>
      </Link>
    );
  };

  const isSmallScreen = screenWidth <= MEDIA_QUERIES.TABLET.size;

  return (
    <PageWrapper>
      {showNavigation && (
        <NavWrapper>
          <LogoWrapper>
            {!isSmallScreen && (
              <StyledImage
                src="/assets/spocket-logo-white.svg"
                alt="spocket-nav logo"
                aria-label="spocket-logo"
              />
            )}
            {isSmallScreen && (
              <LogoBadge
                src="/assets/icons/spocket-badge.svg"
                alt="spocket badge"
                aria-label="spocket-badge"
              />
            )}
          </LogoWrapper>
          <NavItemContainer>
            <TopNavItemContainer>
              {navValues.map((navItem) => {
                return renderNavItem(navItem);
              })}
            </TopNavItemContainer>
            <SettingsWrapper>{renderNavItem(settings)}</SettingsWrapper>
          </NavItemContainer>
        </NavWrapper>
      )}
      <BodyWrapper>
        <InnerWrapper>
          {showNavigation && <HeaderPanel />}
          {children}
          {showNavigation && <Footer />}
        </InnerWrapper>
      </BodyWrapper>
      <ToastWrapper position="top-center" closeButton={() => {}} />
    </PageWrapper>
  );
};

NavigationWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  showNavigation: PropTypes.bool
};

NavigationWrapper.defaultProps = {
  showNavigation: false
};

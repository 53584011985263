import { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Portal } from './utility/Portal';
import { media } from '../../../utils/media';

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  ${(props) =>
    props.alignRight
      ? `
        background-color: rgba(0, 0, 0, 0.2);
        & .modal-content {
          transform: translateX(375px);
          transition: all 700ms cubic-bezier(0.4, 0, 0.2, 1);
          opacity: 0;
          border: none;
          border-radius: 0;
        }`
      : `
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        & .modal-content {
          transform: translateY(100px);
          transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
          opacity: 0;
        }    
  `}
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  transition-delay: 200ms;
  &.active {
    transition-duration: 250ms;
    transition-delay: 0ms;
    opacity: 1;
    & .modal-content {
      transform: translateY(0);
      opacity: 1;
      transition-delay: 150ms;
      transition-duration: 350ms;
    }
  }
`;

const Content = styled.div`
  box-sizing: border-box;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  box-shadow: none;
  min-height: ${(props) => (props.heightLimitIsSet ? '400px' : 'unset')};

  ${(props) =>
    props.alignRight
      ? `
        position: absolute;
        width: 375px;
        right: 0;
      `
      : `
      max-width: 80%;  
      ${media.NOT_PHONE`padding: 45px 80px;  min-width: 390px;`}
      ${media.PHONE`padding: 20px;`} 
  `}
`;

export const BasicModal = (props) => {
  // set up active state
  const [active, setActive] = useState(false);
  // get spread props out variables
  const { open, onClose, locked, children, alignRight, heightLimitIsSet } = props;
  // Make a reference to the backdrop
  const backdrop = useCallback(
    (node) => {
      // when transition ends set active state to match open prop
      const transitionEnd = () => setActive(open);
      // when esc key press close modal unless locked
      const keyHandler = (e) => !locked && [27].indexOf(e.which) >= 0 && onClose();
      // when clicking the backdrop close modal unless locked
      const clickHandler = (e) => !locked && e.target === node && onClose();
      // const clickHandler = (e) => console.log(!locked, e.target === node, onClose());
      if (node !== null) {
        node.addEventListener('click', clickHandler);
        node.addEventListener('transitionend', transitionEnd);
        window.addEventListener('keyup', keyHandler);
      }
      // on unmount remove listeners
      return () => {
        if (node) {
          node.removeEventListener('click', clickHandler);
          node.removeEventListener('transitionend', transitionEnd);
        }
        window.removeEventListener('keyup', keyHandler);
      };
    },

    [open, locked, onClose]
  );

  // on component unmount - restore Intercom position.
  useEffect(() => {
    return () => {
      if (window.Intercom && alignRight) {
        window.Intercom('update', {
          vertical_padding: 20
        });
      }
    };
  }, []);

  useEffect(() => {
    let timeoutId;

    // for side drawers - push the Intercom icon higher when the drawer is open to prevent overlapping with the action buttons.
    if (window.Intercom && alignRight) {
      if (open) {
        window.Intercom('update', {
          vertical_padding: 120
        });
      } else {
        window.Intercom('update', {
          vertical_padding: 20
        });
      }
    }

    // if open props is true add inert to #root
    // and set active state to true
    if (open) {
      timeoutId = window.setTimeout(() => {
        document.activeElement.blur();
        setActive(open);
        document.querySelector('#__next').setAttribute('inert', 'true');
      }, 10);
    }

    // on unmount remove listeners
    return () => {
      // if component is unmounted, don't update its state (`setActive(open)`) via delayed function
      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }
      document.querySelector('#__next').removeAttribute('inert');
    };
  }, [open, locked]);

  return (
    <>
      {(open || active) && (
        <Portal className="modal-portal">
          <Backdrop alignRight={alignRight} ref={backdrop} className={active && open && 'active'}>
            <Content
              className="modal-content"
              alignRight={alignRight}
              heightLimitIsSet={heightLimitIsSet}>
              {children}
            </Content>
          </Backdrop>
        </Portal>
      )}
    </>
  );
};

BasicModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  locked: PropTypes.bool,
  heightLimitIsSet: PropTypes.bool,
  children: PropTypes.node.isRequired,
  alignRight: PropTypes.bool
};

BasicModal.defaultProps = {
  open: false,
  onClose: () => {},
  locked: false,
  heightLimitIsSet: true,
  alignRight: false
};

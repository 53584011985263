import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div``;

export const BoldXIcon = ({ className }) => {
  return (
    <Wrapper className={className}>
      <svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.98483 5.05155L5.12496 3.27355L6.98378 1.49555C7.34133 1.15455 7.34133 0.599552 6.98378 0.258552C6.62624 -0.0854478 6.0481 -0.0844478 5.69056 0.257552L3.83069 2.03555L1.97083 0.255552C1.61328 -0.0864478 1.0341 -0.0844478 0.676555 0.255552C0.320055 0.597552 0.320055 1.15255 0.676555 1.49355L2.53746 3.27355L0.680737 5.04855C0.323191 5.39055 0.323191 5.94555 0.680737 6.28555C0.859509 6.45755 1.09265 6.54255 1.32683 6.54255C1.56205 6.54255 1.79519 6.45755 1.97396 6.28655L3.83069 4.51055L5.6916 6.28955C5.87037 6.46055 6.10351 6.54555 6.33769 6.54555C6.57187 6.54555 6.80606 6.45955 6.98483 6.28955C7.34237 5.94755 7.34237 5.39355 6.98483 5.05155Z"
          fill="#FFDFDF"
        />
      </svg>
    </Wrapper>
  );
};

BoldXIcon.propTypes = {
  className: PropTypes.string
};

BoldXIcon.defaultProps = {
  className: ''
};

/* eslint-disable consistent-return */
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useState, useCallback } from 'react';

const ToastMessage = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LeftIcon = styled.img`
  margin-right: 10px;
`;

const RightIcon = styled.img`
  margin-left: 10px;
`;

export const createToastMessage = ({ type, message }) => {
  const generateAssets = (messageType) => {
    switch (messageType) {
      case 'error':
        return {
          leftIcon: { url: '/assets/icons/red-check-icon.svg', alt: 'error icon' },
          rightIcon: { url: '/assets/icons/red-close-icon.svg', alt: 'error toast icon' }
        };
      case 'warning':
        return {
          leftIcon: { url: '/assets/icons/warning-icon.svg', alt: 'warning icon' },
          rightIcon: { url: '/assets/icons/yellow-close-icon.svg', alt: 'warning toast icon' }
        };
      default:
        return {
          leftIcon: { url: '/assets/icons/green-check-icon.svg', alt: 'success icon' },
          rightIcon: { url: '/assets/icons/green-close-icon.svg', alt: 'close toast icon' }
        };
    }
  };

  const { leftIcon, rightIcon } = generateAssets(type);

  const toastMessage = () => {
    return (
      <ToastMessage>
        <div>
          <LeftIcon src={leftIcon.url} alt={leftIcon.alt} />
          {message}
        </div>

        <RightIcon src={rightIcon.url} alt={rightIcon.alt} />
      </ToastMessage>
    );
  };

  return toast[type](toastMessage(), {
    autoClose: 3000,
    closeOnClick: true,
    pauseOnHover: true,
    hideProgressBar: true,
    className: `toast ${type}-toast`
  });
};

export const useToggle = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);
  const toggle = useCallback(() => {
    setValue((v) => !v);
  }, []);
  return [value, toggle];
};

export const sortCategories = (data) => {
  const sortedCategories = [];

  if (!data) return [];

  data.forEach((category) => {
    const { parent_category_name, category_id, category_name, parent_category_id } = category;

    if (!parent_category_name || !category_name || !parent_category_id) return;

    const parentCategory = sortedCategories.find(
      (parent) => parent.category === parent_category_name
    );
    if (parentCategory) {
      parentCategory.values = [...parentCategory.values, { id: category_id, name: category_name }];
    }
    sortedCategories.push({
      id: parent_category_id,
      category: parent_category_name,
      values: [{ id: category_id, name: category_name }]
    });
  });

  return sortedCategories;
};

import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  .sk-flow {
    margin: auto;
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .sk-flow-dot {
    width: 25%;
    height: 25%;
    background-color: ${(props) => (props.color ? props.color : props.theme.colors.purple40)};
    border-radius: 50%;
    animation: sk-flow 1.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s infinite both;
  }

  .sk-flow-dot:nth-child(1) {
    animation-delay: -0.3s;
  }
  .sk-flow-dot:nth-child(2) {
    animation-delay: -0.15s;
  }

  @keyframes sk-flow {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
  ${(props) =>
    props.centered &&
    `
  width: 100%;
  display: flex;
  margin-top: 100px;
  `}
`;
export const LoadingAnimation = (props) => {
  const { size, color, testId, centered, className } = props;

  return (
    <Wrapper size={size} centered={centered} color={color} className={className}>
      <div className="sk-flow" data-testid={`loading-animation-${testId}`}>
        <div className="sk-flow-dot" />
        <div className="sk-flow-dot" />
        <div className="sk-flow-dot" />
      </div>
    </Wrapper>
  );
};

LoadingAnimation.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  testId: PropTypes.string,
  centered: PropTypes.bool,
  className: PropTypes.string
};

LoadingAnimation.defaultProps = {
  size: 60,
  color: '',
  testId: '',
  centered: false,
  className: ''
};

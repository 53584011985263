import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';

import { BasicModal } from './BasicModal';
import { DataUploader } from '../DataUploader';

const Content = styled.div`
  width: 800px;
  max-width: 100%;
`;

const Title = styled.h1`
  font-family: Avenir;
  font-weight: 500;
  line-height: 33px;
  color: ${(props) => props.theme.colors.black30};
  margin-bottom: 34px;
`;

export const ImportCSVModal = ({ isOpen, setModal }) => {
  const { t } = useTranslation(['products']);

  return (
    <BasicModal open={isOpen}>
      <Content>
        <Title>{t('import_products')}</Title>
        <DataUploader setModalState={setModal} />
      </Content>
    </BasicModal>
  );
};

ImportCSVModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired
};

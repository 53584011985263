import styled from 'styled-components';
import Popup from 'reactjs-popup';
import { IncomingMajor } from '@shopify/polaris-icons';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';

const Matrix = styled.div`
  display: grid;
  gap: 2px;
  grid-template-columns: 1fr 1fr 1fr;
  cursor: pointer;
`;

const Dot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.purple40};
`;

const StyledPopup = styled(Popup)`
  &-overlay {
    margin-bottom: 20px;
  }
`;

const Menu = styled.div`
  margin-top: 10px;
  background-color: ${(props) => props.theme.colors.white};
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.colors.black20};
  line-height: 22px;
  letter-spacing: -0.32px;
  box-shadow: 0px 4px 20px rgba(34, 41, 57, 0.15);
  border-radius: 8px;
  min-width: 200px;
`;

const MenuItem = styled.div`
  display: flex;
  padding: 10px 20px;
  cursor: pointer;
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  align-items: flex-end;
  justify-content: flex-start;
  svg {
    height: 16px;
    margin-right: 12px;
  }
  :hover {
    background: ${(props) => props.theme.colors.purple01};
  }
`;

const menuItems = [
  { link: '/orders/import_tracking', label: 'Import Tracking Numbers', Icon: IncomingMajor }
];

export const MatrixMenu = () => {
  const { t } = useTranslation(['orders']);
  return (
    <StyledPopup
      trigger={
        <Matrix>
          <Dot />
          <Dot />
          <Dot />
          <Dot />
          <Dot />
          <Dot />
          <Dot />
          <Dot />
          <Dot />
        </Matrix>
      }
      position="bottom right"
      on={['click']}
      arrow={false}
      padBottom="10px">
      <Menu>
        {menuItems.map((value) => {
          const { link, label, Icon } = value;

          return (
            <Link href={link} key={value.label} passHref>
              <MenuItem>
                <Icon />
                {t(label)}
              </MenuItem>
            </Link>
          );
        })}
      </Menu>
    </StyledPopup>
  );
};

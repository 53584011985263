import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Icon } from '@shopify/polaris';

const Wrapper = styled.span`
  span {
    width: ${(props) => props.width};
    margin: ${(props) => props.margin};
  }
  svg > path {
    fill: ${(props) => props.color};
  }
`;

export const CustomIcon = ({ iconType, width, margin, testId, color }) => {
  return (
    <Wrapper width={width} margin={margin} data-testid={`${testId}-icon`} color={color}>
      <Icon source={iconType} />
    </Wrapper>
  );
};

CustomIcon.propTypes = {
  iconType: PropTypes.func.isRequired,
  margin: PropTypes.string,
  width: PropTypes.string,
  testId: PropTypes.string,
  color: PropTypes.string
};
CustomIcon.defaultProps = {
  margin: '0 15px 0 0',
  width: '20px',
  testId: '',
  color: '#FFF'
};

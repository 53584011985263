import styled from 'styled-components';
import { useTranslation, Trans } from 'next-i18next';

const Wrapper = styled.div`
  width: 100%;
  margin: 40px 0;
  text-align: center;
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.grey31};
  a {
    color: ${(props) => props.theme.colors.grey31};
  }
`;

const FooterText = styled.span``;

const FooterLink = styled.a`
  margin: 0 5px;
`;

export const Footer = () => {
  const { t } = useTranslation(['common']);

  const links = [
    {
      url: 'https://www.spocket.co/supplier-privacy-policy',
      title: t('footer.privacy_policy')
    },
    {
      url: 'https://www.spocket.co/supplier-terms-of-service',
      title: t('footer.terms_and_conditions')
    },
    {
      url: 'https://www.spocket.co/supplier-data-processing-addendum',
      title: t('footer.data_processing')
    }
  ];
  return (
    <Wrapper>
      <FooterText>
        <Trans
          i18nKey="footer.copyright"
          defaults="Copyright {{date}} @ Spocket. All rights reserved."
          values={{ date: new Date().getFullYear() }}
        />{' '}
      </FooterText>
      {links.map((link) => {
        const { url, title } = link;
        return (
          <span key={title}>
            |
            <FooterLink href={url} target="_blank" rel="noopener noreferrer">
              {title}
            </FooterLink>
          </span>
        );
      })}
    </Wrapper>
  );
};

/* eslint-disable @next/next/no-img-element */
import PropTypes from 'prop-types';

export const Icon = ({ src, alt, ariaLabel, dataTestid, className }) => {
  return (
    <img
      src={src}
      alt={alt}
      aria-label={ariaLabel}
      data-testid={dataTestid}
      className={className}
    />
  );
};

Icon.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  ariaLabel: PropTypes.string,
  dataTestid: PropTypes.string,
  className: PropTypes.string
};

Icon.defaultProps = {
  src: '',
  alt: '',
  ariaLabel: '',
  dataTestid: '',
  className: ''
};

import DOMPurify from 'dompurify';

import axios from 'axios';
import {
  SEARCH_MY_PRODUCTS_QUERY,
  SEARCH_MY_ORDERS_QUERY,
  FETCH_ORDER_QUERY,
  PAYMENT_INFO_QUERY_KEY
} from '../services/api';

import { ORDER_STATUS } from '../const/common';

const EXPORT_CSV_URL = '/listings/download_csv_file';
const host = process.env.NEXT_PUBLIC_API_URL;

export const formatBytes = (bytes, decimals = 2) => {
  if (!Number.isInteger(bytes) || bytes <= 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export function numberWithCommas(x) {
  if (!x || !Number.isInteger(x)) {
    return x === 0 ? '0' : undefined;
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const sortCategoriesByAlpha = (a, b) => {
  const nameA = a.category?.category_name.toUpperCase() || '';
  const nameB = b.category?.category_name.toUpperCase() || '';
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

export const transformAndSortCategories = (data) => {
  if (!data || !Array.isArray(data) || data.length === 0) {
    return [];
  }

  const tree = new Map();

  // only serves the structure with one nesting level: "parent -> children", no grandchildren supported.
  data.forEach((nextCategory) => {
    const { category_id, category_name, parent_category_id, parent_category_name } = nextCategory;
    if (!category_id) return;

    if (parent_category_id) {
      const { children = [] } = tree.get(parent_category_id) || {};
      const updatedChildren = [...children, { category_id, category_name }];
      // adds or resets the parent, adds a new child to array of children
      tree.set(parent_category_id, {
        category: { category_id: parent_category_id, category_name: parent_category_name },
        children: updatedChildren
      });
    } else if (!tree.has(category_id)) {
      // parent is inserted for the first time
      tree.set(category_id, { category: nextCategory, children: [] });
    } // if the node with category_id already exists - do nothing, it was already inserted on previous iterations
  });

  // sort parents alphabetically
  const sorted = [...tree.values()].sort(sortCategoriesByAlpha);
  // sort children alphabetically
  sorted.forEach((next) => next.children.sort(sortCategoriesByAlpha));

  return sorted;
};

export const formatListingsCategory = (data = []) => {
  return data.map((nextCategory) => {
    const { id: category_id, name: category_name, children = [] } = nextCategory || {};
    const parent = { category_id, category_name };

    if (children.length > 0) {
      return {
        category: parent,
        children: children.map((next) => {
          return { category_id: next.id, category_name: next.name };
        })
      };
    }

    return { category: parent };
  });
};

export const mutateCachedProductsPage = ({ queryClient, page, ids = [], property, value }) => {
  const queryData = queryClient.getQueryData([SEARCH_MY_PRODUCTS_QUERY, page]);
  const { data: products } = queryData || {};

  const updatedListings = products.listings.map((listing) => {
    if (ids.indexOf(listing.id) >= 0) {
      return { ...listing, [property]: value };
    }
    return listing;
  });

  // update query with new status for product
  const updatedQuery = { ...queryData, data: { ...products, listings: updatedListings } };
  queryClient.setQueryData([SEARCH_MY_PRODUCTS_QUERY, page], updatedQuery);
};

export const mutateCachedOrdersPage = ({ queryClient, page, ids = [], property, value }) => {
  const queryData = queryClient.getQueryData([SEARCH_MY_ORDERS_QUERY, page]);
  const { data: orders } = queryData || {};

  const updatedOrders = orders.orders.map((order) => {
    if (ids.indexOf(order.id) >= 0) {
      return { ...order, [property]: value };
    }
    return order;
  });

  // update query with new status for orders
  const updatedQuery = { ...queryData, data: { ...orders, orders: updatedOrders } };
  queryClient.setQueryData([SEARCH_MY_ORDERS_QUERY, page], updatedQuery);
};

export const emptyFulfillmentStatusFilters = {
  unfulfilled: false,
  processing: false,
  fulfilled: false,
  cancelled: false
};

export const buildFulfillmentFilters = (fulfillment_status) => {
  if (!fulfillment_status || fulfillment_status.length === 0) {
    return { ...emptyFulfillmentStatusFilters };
  }

  const fulfillmentFilters = { ...emptyFulfillmentStatusFilters };

  fulfillment_status.split(',').forEach((type) => {
    if (type in emptyFulfillmentStatusFilters) {
      fulfillmentFilters[type] = true;
    }
  });

  return fulfillmentFilters;
};

export const mutateCachedOrder = ({ queryClient, orderId, updatedFields }) => {
  const queryData = queryClient.getQueryData([FETCH_ORDER_QUERY, orderId]) || {};

  // update query with updated fields data
  const updatedQuery = { ...queryData, ...updatedFields };
  queryClient.setQueryData([FETCH_ORDER_QUERY, orderId], updatedQuery);
};

export const calculateDaysToFulfillWithin = (dateToFulfill, daysLabel, overdueLabel) => {
  const daysBetween = Math.round(
    (new Date(Date.parse(dateToFulfill)).getTime() - new Date(Date.now()).getTime()) /
      (1000 * 3600 * 24)
  );
  return daysBetween > 0 ? `${daysBetween} ${daysLabel}` : overdueLabel;
};

export const convertMomentToString = (date, delimiter = '/') => {
  return date?.format(`YYYY${delimiter}MM${delimiter}DD`) || '';
};

export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const mutateRemoveCachedPayPalAccount = ({ queryClient, updatedFields }) => {
  const queryData = queryClient.getQueryData(PAYMENT_INFO_QUERY_KEY) || {};

  // update query with updated fields data
  const updatedQuery = { ...queryData, ...updatedFields };
  queryClient.setQueryData(PAYMENT_INFO_QUERY_KEY, updatedQuery);
};

// ********************** ORDER STATUS utility code **********************

const {
  ORDER_PLACED,
  BEGIN_FULFILLMENT,
  ATTACH_INVOICE,
  ADD_TRACKING,
  FULFILLED,
  IN_TRANSIT,
  DELIVERED
} = ORDER_STATUS;

export const getStatusFlags = (stepsFromProps) => {
  const hasTrackingInfo = !!stepsFromProps.find((next) => next.status === 'ADD_TRACKING')?.date;
  const hasAnyDeliveryUpdates = !!stepsFromProps.some(
    (next) => [IN_TRANSIT, DELIVERED].includes(next.status) && !!next.date
  );

  return { hasTrackingInfo, hasAnyDeliveryUpdates };
};

// Server returns all the statuses back to client using the following object format:
//
// def status_details
// [
//   { status: 'ORDER_PLACED', date: object.created_at.strftime('%Y-%m-%d') },
//   { status: 'BEGIN_FULFILLMENT', date: object&.begin_fulfillment_date&.strftime('%Y-%m-%d') },
//   { status: 'ATTACH_INVOICE', date: object&.attach_invoice_date&.strftime('%Y-%m-%d') },
//   { status: 'ADD_TRACKING', date: shipping_label&.created_at&.strftime('%Y-%m-%d') },
//   { status: 'IN_TRANSIT', date: shipping_label&.shipped_at&.strftime('%Y-%m-%d') },
//   { status: 'DELIVERED', date: shipping_label&.delivered_at&.strftime('%Y-%m-%d') },
//   { status: 'FULFILLED', date: shipping_label&.delivered_at&.strftime('%Y-%m-%d') }
//   { status: 'CANCELLED', date: object&.cancelled_at&.strftime('%Y-%m-%d') }
// ]
// end
//
// Client code, based on the date value of ADD_TRACKING's date, decides which timeline to render on the page for the individual order.
//
export const calculateOrderStatusTimeline = (stepsFromProps) => {
  let steps = [];

  const { hasTrackingInfo, hasAnyDeliveryUpdates } = getStatusFlags(stepsFromProps);

  if (!hasTrackingInfo || (hasTrackingInfo && !hasAnyDeliveryUpdates)) {
    // default scenario, until any delivery statuses started to come in
    steps = stepsFromProps.filter((next) =>
      [ORDER_PLACED, BEGIN_FULFILLMENT, ATTACH_INVOICE, ADD_TRACKING, FULFILLED].includes(
        next.status
      )
    );
  } else {
    // "tracking info added" scenario
    steps = stepsFromProps.filter((next) =>
      [
        ORDER_PLACED,
        BEGIN_FULFILLMENT,
        ATTACH_INVOICE,
        ADD_TRACKING,
        IN_TRANSIT,
        DELIVERED
      ].includes(next.status)
    );
  }

  return steps;
};

export const downloadCSV = async (url, fileName) => {
  const { data: blob } = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('auth_token')}`
    },
    responseType: 'blob'
  });

  const newURL = window.URL.createObjectURL(new Blob([blob], { type: 'text/csv;charset=utf-8;' }));

  const link = document.createElement('a');
  link.href = newURL;
  link.download = `${fileName}.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const exportCSV = async (fileName) => {
  const { data: blob } = await axios.post(
    host + EXPORT_CSV_URL,
    {},
    {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('auth_token')}`
      },
      responseType: 'blob'
    }
  );

  const newURL = window.URL.createObjectURL(new Blob([blob], { type: 'text/csv;charset=utf-8;' }));

  const link = document.createElement('a');
  link.href = newURL;
  link.download = `${fileName}.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// **********************  ----  **********************

export function sanitize(string) {
  return DOMPurify.sanitize(string);
}

import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation, Trans } from 'next-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { DeleteMajor } from '@shopify/polaris-icons';
import { Button } from './Button';
import { FileIcon, ErrorCSV } from './UploaderAssets';

import { importSupplierListingsInCSV } from '../../services/api';
import { createToastMessage } from '../../utils/hooks';

import { formatBytes } from '../../utils/helpers';

import { BoldXIcon } from './icons/BoldXIcon';

const Wrapper = styled.div`
  margin-bottom: 45px;
  color: ${(props) => props.theme.colors.black01};
`;

const UploadArea = styled.div`
  width: calc(100%-20px);
  margin-bottom: 20px;
  padding: 30px 0 30px 0;
  border: 2px dashed ${(props) => props.theme.colors.grey13};
  border-radius: 6px;
  font-family: Avenir;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const IconWrapper = styled.div`
  padding: 10px;
  cursor: pointer;
`;

const CenteredContainer = styled.div`
  align-items: center;
  cursor: pointer;
  button {
    text-transform: capitalize;
  }
`;

const XIcon = styled(BoldXIcon)`
  display: flex;
  cursor: pointer;
  width: 30px;
  height: 30px;
  padding: 6px 5px 0 6px;
`;

const ReadyContainer = styled.div`
  padding-left: 30px;
`;

const FileRow = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  svg {
    height: 18px;
    path {
      fill: ${(props) => props.theme.colors.black01};
    }
  }
`;

const FileData = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: Avenir;
  font-weight: 400;
  line-height: 20px;
  margin-left: 15px;
`;

const FileName = styled.div`
  font-size: 12px;
  min-width: 100px;
`;

const FileSize = styled.div`
  font-size: 10px;
  opacity: 0.7;
`;

const FileTypeAllowed = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-transform: capitalize;
`;

const Instruction = styled.div`
  margin: 20px 0 15px 0;
`;

const StyledButton = styled(Button)`
  padding: 10px 26px;
  min-width: 100px;
  font-weight: 900;
  border-radius: 6px;
`;

const Buttons = styled.div`
  display: flex;
  margin-top: 25px;
  justify-content: ${(props) => props.buttonPosition};
  ${StyledButton}:first-child {
    background: ${(props) => props.theme.colors.grey07};
    color: ${(props) => props.theme.colors.black};
    margin-right: 10px;
    :hover {
      background: ${(props) => props.theme.colors.purple01};
    }
  }
`;

const DescriptionContainer = styled.div`
  gap: 10px;
  margin-bottom: 10px;
`;

const DescriptionRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const DescriptionCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const Description = styled.p`
  font-family: Avenir;
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => props.theme.colors.grey90};
`;

const Badge = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1px 6px;
  border-radius: 55px;
  background-color: #fbf8e5;
`;

const BadgeText = styled.p`
  font-family: Avenir;
  font-style: italic;
  font-weight: 500;
  font-size: 10px;
  margin: 0;
  color: #866e42;
`;

const UploadLink = styled.a`
  cursor: pointer;
  color: ${(props) => props.theme.colors.purple04};
  margin-left: 2.8px;
  margin-right: 2.8px;
  :hover {
    text-decoration: none;
    color: ${(props) => props.theme.colors.purple04};
  }
`;

const UploadLinkCSV = styled.a`
  cursor: pointer;
  color: ${(props) => props.theme.colors.purple04};
  margin-left: 2.8px;
  margin-right: 2.8px;
  text-decoration: underline;
  :hover {
    color: ${(props) => props.theme.colors.purple04};
  }
`;

const OutLinkIcon = styled.img`
  margin-right: 4px;
  width: 12px;
  height: 12px;
  align-self: center;
`;

const STATES = {
  INITIAL: 'INITIAL',
  READY: 'READY',
  COMPLETE: 'COMPLETE'
};

export const DataUploader = ({ setModalState }) => {
  const { t } = useTranslation();

  const [file, setFile] = useState();
  const [error, setError] = useState();
  const [uploadState, setUploadState] = useState(STATES.INITIAL);
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setLoading] = useState(false);

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, fileRejections } =
    useDropzone({
      accept: '.csv',
      multiple: false,
      onDrop: (acceptedFiles) => {
        setUploadState(STATES.READY);
        setFile(acceptedFiles[0]);
        setError(null);
      }
    });

  const removeFile = (e) => {
    e.stopPropagation();
    setUploadState(STATES.INITIAL);
    setFile(null);
    setError(null);
  };

  const startUpload = async () => {
    setLoading(true);

    const bodyFormData = new FormData();
    bodyFormData.append('attachment', file);

    try {
      const res = await importSupplierListingsInCSV(bodyFormData);
      setUploadState(STATES.COMPLETE);
      setModalState(false);
      createToastMessage({
        type: 'success',
        message: t(res.data.message)
      });
    } catch (err) {
      setError(err.response.data);
    }
    setLoading(false);
    setUploadState(STATES.INITIAL);
    setFile(null);
  };

  const { name = '', size = 0 } = file || {};

  return (
    <Wrapper>
      {error && <ErrorCSV error={error} />}
      <DescriptionContainer>
        <DescriptionRow>
          <DescriptionCol>
            <Description>
              <Trans
                i18nKey="products:download_csv_template"
                components={{
                  1: (
                    <UploadLink href="https://d2nxps5jx3f309.cloudfront.net/supplier-listings-updates/example_template.csv" />
                  )
                }}
              />
            </Description>
          </DescriptionCol>
          <DescriptionCol>
            <Badge>
              <BadgeText>{t('products:badge_text')}</BadgeText>
            </Badge>
          </DescriptionCol>
        </DescriptionRow>
        <Description>
          <Trans
            i18nKey="products:video_tutorial"
            components={{
              1: (
                <UploadLink
                  href="https://www.youtube.com/watch?v=l_mkCKkAhbQ"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
              2: <OutLinkIcon src="/assets/icons/out-link.svg" alt="icon" />
            }}
          />
        </Description>
      </DescriptionContainer>
      <UploadArea {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
        {uploadState === STATES.INITIAL && (
          <CenteredContainer>
            <FileIcon />
            <Instruction>
              <Trans
                i18nKey="products:select_file_or_dragndrop"
                defaults="<1>Upload</1> or drop your file here"
                components={{
                  1: <UploadLinkCSV />
                }}
              />
            </Instruction>
            <FileTypeAllowed>CSV {t('common:file')}</FileTypeAllowed>
            <input {...getInputProps()} placeholder="Browse" type="file" accept=".csv" />
          </CenteredContainer>
        )}
        {uploadState === STATES.READY && (
          <ReadyContainer>
            {fileRejections.length > 0 ? (
              <FileRow>
                <FileIcon extraSmall />
                <FileData>
                  <Trans
                    i18nKey="upload_csv_error"
                    defaults="<1>File not accepted, select a CSV with our CSV template</1>"
                    components={{
                      1: <FileName />
                    }}
                  />
                </FileData>
                <IconWrapper onClick={(e) => removeFile(e)}>
                  <DeleteMajor />
                </IconWrapper>
              </FileRow>
            ) : (
              <>
                <FileRow>
                  <FileIcon extraSmall />
                  <FileData>
                    <FileName>{name}</FileName>
                    <FileSize>{formatBytes(size)}</FileSize>
                  </FileData>
                  <IconWrapper onClick={(e) => removeFile(e)}>
                    <XIcon />
                  </IconWrapper>
                </FileRow>
                <input {...getInputProps()} placeholder="Browse" type="file" accept=".csv" />
              </>
            )}
          </ReadyContainer>
        )}
        {uploadState === STATES.COMPLETE && (
          <CenteredContainer>
            <input {...getInputProps()} placeholder="Browse" type="file" accept=".csv" />
          </CenteredContainer>
        )}
      </UploadArea>
      <Buttons buttonPosition="flex-end">
        <StyledButton inverse onClick={() => setModalState(false)}>
          {t('common:cancel')}
        </StyledButton>
        <StyledButton
          onClick={startUpload}
          disabled={uploadState !== STATES.READY || fileRejections.length > 0 || isLoading}>
          {t('common:upload')}
        </StyledButton>
      </Buttons>
    </Wrapper>
  );
};

DataUploader.propTypes = {
  setModalState: PropTypes.func.isRequired
};

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadIntercom, initIntercomWindow } from 'next-intercom';
import styled from 'styled-components';

import { NavigationWrapper } from './NavigationWrapper';
import { signInUser, updateSettings, verify } from '../../src_old/actions/index';
import { LoadingAnimation } from './common/LoadingAnimation';

const StyledLoadingAnimation = styled(LoadingAnimation)`
  margin-top: 100px;
`;

function AuthLayoutWrapper(props) {
  const {
    children,
    authenticated,
    showLoading,
    migratedFrom,
    hasIntegrations,
    supplier,
    supplier: { registration_finished_at },
    showNavigation
  } = props;
  const hasCompletedOnboardingForm = registration_finished_at && registration_finished_at !== null;

  const router = useRouter();

  // Use isReady https://github.com/vercel/next.js/discussions/11484#discussioncomment-356055
  // https://nextjs.org/docs/api-reference/next/router#router-object
  const { query, pathname, isReady } = router;

  const shouldRedirectToWelcome = () => {
    // Redirect matching users to /welcome page except those that are already on /registration-details page.
    // That page is a next step after /welcome but the is_complete status had not been updated yet.
    return (
      pathname !== '/welcome' &&
      pathname !== '/registration-details' &&
      hasIntegrations &&
      !hasCompletedOnboardingForm
    );
  };

  /**
   * TODO - This code was removed after request through Shopify
    const shouldRedirectToThankYou = () => {
      return hasIntegrations && !is_approved && hasCompletedOnboardingForm;
    };
   */

  const shouldRedirectToDashboard = () => {
    return authenticated && pathname === '/welcome' && !hasIntegrations;
  };

  useEffect(() => {
    if (isReady) {
      // handles redirects from Shopify that include auth_token URL parameter
      if (query.auth_token) {
        props.signInUser({ auth_token: query.auth_token }, router);
        // exit this hook, let the user authorize and be redirected to dashboard from action handler.
        return;
      }

      if (!localStorage.getItem('auth_token')) {
        router.push('/login');
        return;
      }

      if (showLoading && Object.keys(supplier).length === 0) {
        props.updateSettings('GET', {});
        props.verify();
      }
    }
  }, [isReady]);

  useEffect(() => {
    if (Object.keys(supplier).length > 0 && migratedFrom === 'shopify') {
      if (!hasCompletedOnboardingForm) {
        if (shouldRedirectToWelcome()) {
          router.push('/welcome');
          return;
        }

        if (shouldRedirectToDashboard()) {
          router.push('/');
          return;
        }

        if (pathname === '/register') {
          // Register fallback rule
          // This was added because users that came from spocket have a has_integrations set as false
          // ! The backend will work on this, so we could just remove this in the future
          router.push('/');
          return;
        }
      }
    }
    if (pathname === '/register' && query.auth_token) {
      router.push('/');
    }
  }, [migratedFrom, supplier, authenticated, hasIntegrations]);

  const { email, name } = supplier;

  useEffect(() => {
    loadIntercom({
      appId: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
      ssr: false,
      initWindow: false,
      delay: 0
    });

    if (window.Intercom) {
      // helps to convert sessions when user moves from Login to Dashboard, and also updates session during pages navigation.
      window.Intercom('update');
    }
  }, []);

  useEffect(() => {
    if (Object.keys(supplier).length > 0) {
      initIntercomWindow({
        appId: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
        ssr: false,
        initWindow: true,
        delay: 0,
        name,
        email
      });
    }
  }, [email]);

  // TODO: investigating why the path "/" renders for a fraction of a second before some of the redirects from useEffect() above
  const isLoading = showLoading || !isReady;

  return (
    <>
      {isLoading && <StyledLoadingAnimation testId="auth-layout" />}
      {!isLoading && showNavigation && (
        <NavigationWrapper showNavigation={showNavigation}>{children}</NavigationWrapper>
      )}
      {!isLoading && !showNavigation && children}
    </>
  );
}

function mapStateToProps(state) {
  const supplier = state.settings.settings.supplier || {};
  return {
    authenticated: state.auth.authenticated,
    migratedFrom: supplier.migrated_from,
    hasIntegrations: supplier.has_integrations,
    supplier,
    showLoading: state.auth.showLoading
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ signInUser, updateSettings, verify }, dispatch);
}

export const AuthLayout = connect(mapStateToProps, mapDispatchToProps)(AuthLayoutWrapper);

AuthLayoutWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  authenticated: PropTypes.bool,
  signInUser: PropTypes.func,
  showLoading: PropTypes.bool,
  updateSettings: PropTypes.func,
  verify: PropTypes.func,
  migratedFrom: PropTypes.string,
  hasIntegrations: PropTypes.bool,
  showNavigation: PropTypes.bool,
  supplier: PropTypes.oneOfType([PropTypes.object])
};

AuthLayoutWrapper.defaultProps = {
  authenticated: false,
  showLoading: true,
  migratedFrom: undefined,
  hasIntegrations: undefined,
  signInUser: () => {},
  verify: () => {},
  updateSettings: () => {},
  supplier: {},
  showNavigation: true
};

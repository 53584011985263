import styled from 'styled-components';
import PropTypes from 'prop-types';

import { LoadingAnimation } from './LoadingAnimation';

const ButtonWrapper = styled.button`
  position: relative;
  border-radius: 6px;
  outline: none;
  border: 1px solid;
  height: fit-content;
  color: ${(props) => (props.inverse ? props.theme.colors.grey80 : props.theme.colors.white)};
  border-color: ${(props) => (props.inverse ? props.theme.colors.grey01 : 'transparent')};
  background-color: ${(props) =>
    props.inverse ? props.theme.colors.white : props.theme.colors.purple40};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  outline: none;
  font-family: Avenir;
  padding: 15px;
  :hover {
    background-color: ${(props) =>
      props.inverse ? props.theme.colors.white : props.theme.colors.purple50};
  }
  /* TODO: "inverse" likely mean "secondary" and should be updated all around this styling sheet */
  ${(props) =>
    props.disabled &&
    `
    background-color: ${props.inverse ? props.theme.colors.grey05 : props.theme.colors.purple08};
    color: ${props.inverse ? props.theme.colors.grey16 : props.theme.colors.purple09};
    :hover {
      background-color: ${props.inverse ? props.theme.colors.grey05 : props.theme.colors.purple08};
      color: ${props.inverse ? props.theme.colors.grey16 : props.theme.colors.purple09};
    }
  `}
  // TODO: Build a better system to support button variants. See:
  // * Design System in Figma - https://www.figma.com/file/VkinTNMs53vCmSHluKVNvT/Supplier-%2F-Dashboard?node-id=800%3A0
  // * related article - https://blog.bitsrc.io/design-systems-react-buttons-with-the-base-variant-pattern-c56a3b394aaf
  ${(props) => props.variant === 'short' && `padding: 10px 20px; font-weight: 500;`}
  ${(props) =>
    props.variant === 'secondary' &&
    `
     background-color: ${props.theme.colors.white};
     border: 1px solid ${props.theme.colors.grey01};
     color: ${props.theme.colors.black15};
     font-weight: 500;
     font-weight: 500; 
     padding: 10px 20px;
     ${
       !props.disabled &&
       `:hover {
          background-color: ${props.theme.colors.grey15};
        }`
     };
     ${
       props.disabled &&
       `
          color: ${props.theme.colors.grey16}; 
          background-color: ${props.theme.colors.grey15};
          :hover {
            color: ${props.theme.colors.grey16}; 
            background-color: ${props.theme.colors.grey15};
          }
        `
     };

    `}
`;

const Content = styled.div`
  ${(props) => props.isLoading && `visibility: hidden;`}
`;

// animation centered in the middle of the button
const Loading = styled(LoadingAnimation)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

// TODO need a design for disabled state & is loading icon
export const Button = ({
  variant,
  inverse,
  onClick,
  children,
  disabled,
  isLoading,
  className,
  loadingAnimationSize,
  testId,
  dataCy
}) => {
  return (
    <ButtonWrapper
      data-cy={dataCy}
      disabled={disabled}
      onClick={onClick}
      inverse={inverse}
      className={className}
      variant={variant}>
      <Content isLoading={isLoading}>{children}</Content>
      {isLoading && <Loading size={loadingAnimationSize} testId={testId} />}
    </ButtonWrapper>
  );
};

Button.propTypes = {
  inverse: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  loadingAnimationSize: PropTypes.number,
  variant: PropTypes.oneOf(['short', 'secondary', '']),
  testId: PropTypes.string,
  dataCy: PropTypes.string
};

Button.defaultProps = {
  inverse: false,
  isLoading: false,
  disabled: false,
  className: '',
  onClick: () => {},
  variant: '',
  loadingAnimationSize: 18, // random number. TODO: subject to cleanup as per ticket #394.
  testId: '',
  dataCy: ''
};

import styled from 'styled-components';
import PropTypes from 'prop-types';

const LinkWrapper = styled.div`
  margin-right: 10px;
  width: 37px;
  cursor: pointer;
  padding: 10px 0 0 0;
  :hover {
    svg > path {
      stroke-width: 2px;
    }
  }
`;

export const BackLinkIcon = ({ clickHandler }) => {
  return (
    <LinkWrapper onClick={clickHandler} data-testid="back-link-button">
      <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7 1L1 7L7 13"
          stroke="#081F40"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </LinkWrapper>
  );
};

BackLinkIcon.defaultProps = {
  clickHandler: () => {}
};

BackLinkIcon.propTypes = {
  clickHandler: PropTypes.func
};
